import { useState } from 'react';

import {
  useToast,
  Box,
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Image,
  useDisclosure,
  Input,
  Divider,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

import { ArrowForwardIcon, InfoOutlineIcon } from '@chakra-ui/icons';

import { useGetBrandDataQuery, useUpdateMutation } from '../../Redux/features/auth/authApiSlice';
import { useAddCompetitorMutation } from '../../Redux/features/competitors/competitorsApiSlice';
import { useGetAdsQuery } from '../../Redux/features/ads/adsApiSlice';

import ScreenLoader from '../ScreenLoader/ScreenLoader';
import ErrorLoadingDataPage from '../../Pages/ErrorLoadingDataPage';
import TallyFeedbackForm from '../TallyFeedbackForm/TallyFeedbackForm';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import DashboardCompetitors from './DashboardCompetitors';
import DashboardConfigurations from './DashboardConfigurations';
import DashboardAdItem from './DashboardAdItem';
import Pagination from '../Pagination/Pagination';

import MetaIcon from '../../Assets/Images/meta.png';
import TikTokIcon from '../../Assets/Images/tiktok.png';
import TrackingIcon from '../../Assets/Images/eye.png';
import CheckIcon from '../../Assets/Images/mingcute_check-fill.png';
import ModalPricingTable from '../ModalPricingTable/ModalPricingTable';

// Milisegundos que solicitamos los datos del usuario
const POLLING_INTERVAL = 30000;

export default function Dashboard() {
  // Toast
  const toast = useToast();

  // Redux
  const {
    data: brandData,
    isLoading: isLoadingBrandData,
    isSuccess: isSuccessBrandData,
    isError: isErrorBrandData,
  } = useGetBrandDataQuery(null, {
    pollingInterval: POLLING_INTERVAL,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;
  const { data: adsData } = useGetAdsQuery({ pageNumber: currentPage, pageSize });
  const { data: nextPageData } = useGetAdsQuery({ pageNumber: currentPage + 1, pageSize });

  // Determine if the "Next" button should be enabled
  const hasMore = nextPageData && nextPageData?.data?.length > 0;

  const [addCompetitor, { isLoading: isLoadingAddCompetitor }] = useAddCompetitorMutation();
  const [update, { isLoading: isLoadingUpdate }] = useUpdateMutation();

  // Modal nueva marca para trackear
  const {
    isOpen: isOpenAddNewCompetitorModal,
    onOpen: onOpenAddNewCompetitorModal,
    onClose: onCloseAddNewCompetitorModal,
  } = useDisclosure();

  // Modal notificaciones de email
  const {
    isOpen: isOpenNotificationsEmailModal,
    onOpen: onOpenNotificationsEmailModal,
    onClose: onCloseNotificationsEmailModal,
  } = useDisclosure();

  // Modal pricing table
  const {
    isOpen: isOpenPricingTableModal,
    onOpen: onOpenPricingTableModal,
    onClose: onClosePricingTableModal,
  } = useDisclosure();

  // Modal feedback
  const {
    isOpen: isOpenFeedbackModal,
    onOpen: onOpenFeedbackModal,
    onClose: onCloseFeedbackModal,
  } = useDisclosure();

  // Modal feedback
  const {
    isOpen: isOpenLastAdsExplanationModal,
    onOpen: onOpenLastAdsExplanationModal,
    onClose: onCloseLastAdsExplanationModal,
  } = useDisclosure();

  const handleClickOpenPricingTableFromToast = () => {
    onCloseAddNewCompetitorModal();
    onOpenPricingTableModal();
  };

  // Nombre de la marca
  const [brandUrl, setBrandUrl] = useState('');
  const handleBrandUrl = (e) => setBrandUrl(e.target.value);

  // Nombre de la marca
  const [brandName, setBrandName] = useState('');
  const handleBrandName = (e) => setBrandName(e.target.value);

  // Plataforma seleccionada
  const [platforms, setPlatform] = useState([]);
  const togglePlatorm = (platformName) => {
    if (platforms.includes(platformName)) {
      setPlatform(platforms.filter((id) => id !== platformName));
    } else {
      setPlatform([...platforms, platformName]);
    }
  };

  // Email de notificación de la marca
  const [brandNotificationEmail, setBrandNotificationEmail] = useState('');
  const handleBrandNotificationEmail = (e) => setBrandNotificationEmail(e.target.value);

  // Ver si tiene plan y qué plan, si no tiene plan, ponemos "noplan"
  const brandPlan = brandData && brandData.subscription ? brandData.subscription.planId : 'noplan';

  // Función para añadir una marca
  const addBrand = async () => {
    // Si tiene el plan "noplan" y NO tiene ningún competidor, sigue la función.

    // Si ya tiene un competidor y no tiene plan de pago, no dejamos crear más
    if (brandData.competitors.length === 1 && brandPlan === 'noplan') {
      toast({
        status: 'error',
        description: (
          <Box>
            To add another competitor, you must subscribe to a plan. <br />{' '}
            <u style={{ cursor: 'pointer' }} onClick={handleClickOpenPricingTableFromToast}>
              See plans
            </u>
          </Box>
        ),
      });
      return;
    }

    // Si ya tiene un competidor y el plan Freelance (9€), no dejamos crear más
    if (brandData.competitors.length === 1 && brandPlan === 'prod_Q20EaxIjcwg6B6') {
      toast({
        status: 'error',
        description: (
          <Box>
            You have reached your plan's competitor limit. Upgrade your plan to get more.{' '}
            <u style={{ cursor: 'pointer' }} onClick={handleClickOpenPricingTableFromToast}>
              Upgrade
            </u>
          </Box>
        ),
      });
      return;
    }

    // Si ya tiene 5 competidores y el plan Brand (19€), no dejamos crear más
    if (brandData.competitors.length === 5 && brandPlan === 'prod_Q1zn5G7cc5clff') {
      toast({
        status: 'error',
        description: "You have reached your plan's competitor limit. Expand your plan to get more.",
      });
      return;
    }

    // Si ya tiene 5 competidores y el plan Agency (49€), no dejamos crear más
    if (brandData.competitors.length === 20 && brandPlan === 'prod_Q1zsv6eYqVlDDZ') {
      toast({
        status: 'error',
        description:
          "You have reached your plan's competitor limit. Contact with hello@adstrack.app to get a custom plan.",
      });
      return;
    }

    const newCompetitorData = {
      name: brandName,
      sources: platforms,
      siteUrl: brandUrl,
    };

    try {
      const newCompetitorResultData = await addCompetitor(newCompetitorData).unwrap();
      console.log(newCompetitorResultData);
      toast({
        status: 'success',
        description: 'Competitor added successfully',
      });
    } catch (error) {
      console.log(error);
    }

    onCloseAddNewCompetitorModal();
    setBrandName('');
    setBrandUrl('');
    setPlatform([]);
  };

  // Función para hacer update del notification email
  const updateBrand = async () => {
    const data = {
      notificationEmail: brandNotificationEmail,
    };

    try {
      const newCompetitorResultData = await update(data).unwrap();
      console.log(newCompetitorResultData);
      toast({
        status: 'success',
        description: 'Notification email updated',
      });
    } catch (error) {
      console.log(error);
    }

    onCloseNotificationsEmailModal();
    setBrandNotificationEmail('');
  };

  // Mostamos loader full page
  if (isLoadingBrandData) {
    return <ScreenLoader />;
  }

  if (isErrorBrandData) {
    return <ErrorLoadingDataPage />;
  }

  if (isSuccessBrandData) {
    return (
      // Main Box container
      <Flex minH="100vh" flexDir="column">
        {/* Cabecera */}
        <Header
          brandPlan={brandPlan}
          onOpenPricingTableModal={onOpenPricingTableModal}
          onOpenFeedbackModal={onOpenFeedbackModal}
        />

        {/* Sección marcas trackeándose */}
        <Flex mt={10} px="40px" justifyContent="space-between" fontWeight="600">
          {/* Competidores añadidos */}
          <DashboardCompetitors
            brandData={brandData}
            brandPlan={brandPlan}
            onOpenPricingTableModal={onOpenPricingTableModal}
            onOpenAddNewCompetitorModal={onOpenAddNewCompetitorModal}
          />

          {/* Configuraciones */}
          <DashboardConfigurations onOpenNotificationsEmailModal={onOpenNotificationsEmailModal} />
        </Flex>

        {/* Últimos anuncios */}
        <Box mt={10} px="40px" fontWeight="600">
          <Text
            fontFamily="Switzer-Bold"
            fontWeight="bold"
            fontSize="24px"
            onClick={onOpenLastAdsExplanationModal}
            position="relative"
          >
            Last Ads{' '}
            <InfoOutlineIcon
              cursor="pointer"
              height="10px"
              color="rgba(0, 0, 0, 0.48)"
              width="10px"
              mb={3}
            />
          </Text>

          {/* Si no hay anuncios que mostrar (nada más registrarse) */}
          {adsData && adsData?.data && adsData.data.length === 0 && (
            <Flex
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              minH="300px"
              borderRadius="6px"
              mt={8}
              boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.1)"
              border="1px solid #E5E7EB"
            >
              <Image mb={2} width="45px" src={TrackingIcon} />
              <Text fontFamily="Satoshi-Regular">No ads tracked yet</Text>
            </Flex>
          )}

          {/* Si hay anuncios que mostrar, mostramos todos */}
          {adsData && adsData?.data && adsData.data.length !== 0 && (
            <Box mt={8} fontFamily="Satoshi-Medium">
              <Flex flexDir="column">
                {/* Cabecera tabla anuncios */}
                <Flex mb="8px" width="100%">
                  <Flex px={6} alignItems="center">
                    <Box w="100px" mr={6} />
                    <Text w="150px" mr={6}>
                      Name
                    </Text>
                    <Box w="150px" mr={6}>
                      Platform
                    </Box>
                    <Box w="150px" mr={6}>
                      Status
                    </Box>
                    <Box w="250px" mr={6}>
                      Dates{' '}
                      <span style={{ fontSize: '12px' }}>(activation - deactivation day)</span>
                    </Box>
                  </Flex>
                </Flex>

                {/* Todos los anuncios */}
                {adsData &&
                  adsData?.data &&
                  adsData.data.map((ad) => (
                    <DashboardAdItem key={ad.id} adData={ad} brandData={brandData} />
                  ))}
              </Flex>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                hasMore={hasMore}
              />
            </Box>
          )}
        </Box>

        {/* Footer */}
        <Footer />

        {/* Nueva marca modal */}
        <Modal
          size="md"
          isCentered
          isOpen={isOpenAddNewCompetitorModal}
          onClose={onCloseAddNewCompetitorModal}
        >
          <ModalOverlay backdropFilter="blur(1px) hue-rotate(90deg)" />
          <ModalContent
            fontFamily="Satoshi-Medium"
            borderRadius="6px"
            border="1px solid rgba(34, 34, 34, 0.2)"
            m={4}
          >
            <ModalBody pb={6}>
              <Text pt={4} fontFamily="Switzer-Bold" fontSize="24px">
                Start tracking a new competitor
              </Text>
              <Text mb={3} fontSize="16px" fontFamily="Satoshi-Medium">
                Decide which competitor you want to track ads from
              </Text>

              {/* Nombre de la marca */}
              <FormControl>
                <FormLabel mb={1}>Competitor's name</FormLabel>
                <Input value={brandName} onChange={handleBrandName} placeholder="CRUWI" />
              </FormControl>

              {/* Web de la marca */}
              <FormControl mt={4}>
                <FormLabel mb={1}>Competitor's web</FormLabel>
                <Input
                  size="md"
                  value={brandUrl}
                  onChange={handleBrandUrl}
                  placeholder="https://www.cruwi.com"
                />
              </FormControl>

              {/* Plataforma que seguir */}
              <Box mt={4} mb="20px">
                <FormLabel mb={1}>In what libraries do you want to track it?</FormLabel>
                <Flex mt="2px">
                  <Flex
                    w="130px"
                    p={2}
                    alignItems="center"
                    borderRadius="6px"
                    justifyContent="space-between"
                    border={platforms.includes('meta') ? '1px solid black' : '1px solid #e2e8f0'}
                    backgroundColor={platforms.includes('meta') ? 'white' : 'white'}
                    onClick={() => togglePlatorm('meta')}
                    cursor="pointer"
                    mr={2}
                  >
                    <Flex>
                      <Image mr="6px" width="20px" src={MetaIcon} />
                      <Text fontSize="14px">Meta</Text>
                    </Flex>
                    <Box
                      bgImage={platforms.includes('meta') ? CheckIcon : ''}
                      bgSize="9px"
                      bgPosition="2px 3px"
                      bgRepeat="no-repeat"
                      border="1px solid"
                      w="16px"
                      h="16px"
                      borderRadius="50%"
                    />
                  </Flex>
                  <Flex
                    w="130px"
                    p={2}
                    alignItems="center"
                    borderRadius="6px"
                    justifyContent="space-between"
                    border={platforms.includes('tiktok') ? '1px solid black' : '1px solid #e2e8f0'}
                    backgroundColor={platforms.includes('tiktok') ? 'white' : 'white'}
                    onClick={() => togglePlatorm('tiktok')}
                    cursor="pointer"
                    mr={2}
                  >
                    <Flex>
                      <Image mr="6px" width="20px" src={TikTokIcon} />
                      <Text fontSize="14px">TikTok</Text>
                    </Flex>
                    <Box
                      bgImage={platforms.includes('tiktok') ? CheckIcon : ''}
                      bgSize="9px"
                      bgPosition="2px 3px"
                      bgRepeat="no-repeat"
                      border="1px solid"
                      w="16px"
                      h="16px"
                      borderRadius="50%"
                    />
                  </Flex>
                </Flex>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                fontFamily="Satoshi-Regular"
                size="sm"
                variant="outline"
                onClick={onCloseAddNewCompetitorModal}
              >
                Cancel
              </Button>
              <Button
                bg="black"
                color="white"
                _hover={{ bg: 'black', color: 'white' }}
                onClick={addBrand}
                isLoading={isLoadingAddCompetitor}
                ml={3}
                size="sm"
                fontFamily="Satoshi-Regular"
                rightIcon={<ArrowForwardIcon />}
              >
                Start tracking
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Modal configurar notificaciones por Email */}
        <Modal
          size="md"
          isCentered
          isOpen={isOpenNotificationsEmailModal}
          onClose={onCloseNotificationsEmailModal}
        >
          <ModalOverlay backdropFilter="blur(1px) hue-rotate(90deg)" />
          <ModalContent
            fontFamily="Satoshi-Medium"
            borderRadius="6px"
            border="1px solid rgba(34, 34, 34, 0.2)"
            m={4}
          >
            <ModalBody pb={6}>
              <Text pt={4} fontFamily="Switzer-Bold" fontSize="24px">
                Email notifications
              </Text>
              <Text mt={2} mb={3} fontSize="16px" fontFamily="Satoshi-Medium">
                By default, you will receive a notification to{' '}
                <strong>{brandData?.notificationEmail || brandData?.email}</strong> every time a
                tracked competitor publishes a new ad.
              </Text>

              <Text mb={3} fontSize="16px" fontFamily="Satoshi-Medium">
                You can change the email address where you will receive notifications here. Just
                change it and save
              </Text>

              {/* Nombre de la marca */}
              <FormControl>
                <FormLabel mb={1}>Notifications email</FormLabel>
                <Input
                  placeholder="New desired notification email"
                  value={brandNotificationEmail}
                  onChange={handleBrandNotificationEmail}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                fontFamily="Satoshi-Regular"
                size="sm"
                variant="outline"
                onClick={onCloseNotificationsEmailModal}
              >
                Cancel
              </Button>
              <Button
                bg="black"
                color="white"
                _hover={{ bg: 'black', color: 'white' }}
                onClick={updateBrand}
                ml={3}
                size="sm"
                fontFamily="Satoshi-Regular"
                rightIcon={<ArrowForwardIcon />}
                isLoading={isLoadingUpdate}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Modal Feedback tally */}
        <Modal size="xl" isCentered isOpen={isOpenFeedbackModal} onClose={onCloseFeedbackModal}>
          <ModalOverlay backdropFilter="blur(1px) hue-rotate(90deg)" />
          <ModalContent
            fontFamily="Satoshi-Medium"
            borderRadius="6px"
            border="1px solid rgba(34, 34, 34, 0.2)"
            m={4}
          >
            <ModalBody pb={6} position="relative">
              <Text pt={4} fontFamily="Switzer-Bold" fontSize="24px">
                We want to hear from you
              </Text>
              <TallyFeedbackForm brandData={brandData} />
              <Box
                right={8}
                bottom={8}
                height="50px"
                width="200px"
                position="absolute"
                bg="white"
              />
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={onCloseFeedbackModal}
                fontFamily="Satoshi-Regular"
                size="sm"
                variant="outline"
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Modal Last Ads Explanation */}
        <Modal
          size="xl"
          isCentered
          isOpen={isOpenLastAdsExplanationModal}
          onClose={onCloseLastAdsExplanationModal}
        >
          <ModalOverlay backdropFilter="blur(1px) hue-rotate(90deg)" />
          <ModalContent
            fontFamily="Satoshi-Medium"
            borderRadius="6px"
            border="1px solid rgba(34, 34, 34, 0.2)"
            m={4}
          >
            <ModalBody pb={6} position="relative">
              <Text pt={4} fontFamily="Switzer-Bold" fontSize="24px">
                About Last Ads
              </Text>

              <Text mb={3} fontSize="16px" fontFamily="Satoshi-Medium">
                Here you will see new ads from the active brands you are tracking. Each time a
                competitor is added and activated, the last 10 ads they posted will be loaded in
                this section. Thereafter, new ads will be displayed as they are uploaded. The order
                is from newest to oldest. By clicking on "See It" you will see the ad in the
                corresponding platform. To do this you must have at least one active subscription.
              </Text>

              <Divider />

              <Text mt={3} mb={2} fontSize="16px" fontWeight="bold" fontFamily="Satoshi-Medium">
                Important note
              </Text>

              <Text mb={1} fontSize="16px" fontFamily="Satoshi-Medium">
                In order to be able to use Adstrack normally, make sure to:
              </Text>

              <UnorderedList mb={3}>
                <ListItem>disable the Adblock extension if you have it enabled</ListItem>
                <ListItem>be already logged in to your facebook account</ListItem>
              </UnorderedList>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={onCloseLastAdsExplanationModal}
                fontFamily="Satoshi-Regular"
                size="sm"
                variant="outline"
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Modal pricing table full size */}
        <ModalPricingTable
          brandPlan={brandPlan}
          isOpen={isOpenPricingTableModal}
          onClose={onClosePricingTableModal}
        />
      </Flex>
    );
  }
}
