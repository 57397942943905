import { Box, Flex, Text, Button, Image } from '@chakra-ui/react';

import { ArrowForwardIcon } from '@chakra-ui/icons';

import TikTokIcon from '../../Assets/Images/tiktok.png';
import MetaIcon from '../../Assets/Images/meta.png';
import InstagramIcon from '../../Assets/Images/instagram.png';
import MessengerIcon from '../../Assets/Images/messenger.svg';
import NetworkIcon from '../../Assets/Images/network.svg';

function transformDateFormat(isoDateString) {
  const date = new Date(isoDateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export default function DashboardAdItem({ adData, brandData }) {
  // Datos que necesitamos del ad
  const { platforms = [], url, competitorId, startedAt, finishedAt } = adData;

  // Necesitamos al competidor para otros campos
  const { competitors = [] } = brandData;
  const competitor = competitors.find((c) => c.id === competitorId);

  // Función para ver el anuncio
  const handleGoToAd = (direction) => {
    window.open(direction, '_blank').focus();
  };

  // Si hay fecha de inicio y de fin es que ya está INACTIVO (ha finalizado)
  const isActive = adData.finishedAt === undefined;

  return (
    <Flex
      borderRadius="6px"
      alignItems="center"
      justifyContent="space-between"
      mb={4}
      py={4}
      px={6}
      width="100%"
      boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.1)"
      border="1px solid #E5E7EB"
    >
      <Flex alignItems="center">
        <Box w="100px" mr={6}>
          <Image borderRadius="6px" w="37px" h="37px" src={competitor?.logoUrl} />
        </Box>

        <Box w="150px" mr={6}>
          <Text fontSize="16px">{competitor?.name}</Text>
        </Box>

        <Flex w="150px" mr={6}>
          {platforms.includes('tiktok') && (
            <Flex
              alignItems="center"
              p={0}
              display="flex"
              borderRadius="10px"
              height="34px"
              width="34px"
              bg="#E5E7EB"
              justifyContent="center"
              mr={1}
            >
              <Image width="24px" src={TikTokIcon} />
            </Flex>
          )}

          {platforms.includes('facebook') && (
            <Flex
              alignItems="center"
              p={0}
              display="flex"
              borderRadius="10px"
              height="34px"
              width="34px"
              bg="#E5E7EB"
              justifyContent="center"
              mr={1}
            >
              <Image width="24px" src={MetaIcon} />
            </Flex>
          )}

          {platforms.includes('instagram') && (
            <Flex
              alignItems="center"
              p={0}
              display="flex"
              borderRadius="10px"
              height="34px"
              width="34px"
              bg="#E5E7EB"
              justifyContent="center"
              mr={1}
            >
              <Image width="24px" src={InstagramIcon} />
            </Flex>
          )}

          {platforms.includes('messenger') && (
            <Flex
              alignItems="center"
              p={0}
              display="flex"
              borderRadius="10px"
              height="34px"
              width="34px"
              bg="#E5E7EB"
              justifyContent="center"
              mr={1}
            >
              <Image width="24px" src={MessengerIcon} />
            </Flex>
          )}

          {platforms.includes('audience_network') && (
            <Flex
              alignItems="center"
              p={0}
              display="flex"
              borderRadius="10px"
              height="34px"
              width="34px"
              bg="#E5E7EB"
              justifyContent="center"
              mr={1}
            >
              <Image width="24px" src={NetworkIcon} />
            </Flex>
          )}
        </Flex>

        <Box w="150px" mr={6}>
          <Box
            py="8px"
            px="24px"
            bg={isActive ? '#D9FFBF' : '#'}
            display="inline-block"
            borderRadius="8px"
            border="1px solid rgba(34, 34, 34, 0.1)"
            fontSize="16px"
            width="110px"
            textAlign="center"
          >
            <Text lineHeight={1}>{isActive ? 'Active' : 'Inactive'}</Text>
          </Box>
        </Box>

        <Box w="250px" mr={6}>
          <Text fontSize="16px">
            {transformDateFormat(startedAt)} -{' '}
            {isActive ? 'Still running' : transformDateFormat(finishedAt)}
          </Text>
        </Box>
      </Flex>

      <Box>
        <Button
          _hover={{ bg: 'whitesmoke' }}
          bg="white"
          border="1px solid #E5E7EB"
          size="md"
          as={Button}
          rightIcon={<ArrowForwardIcon />}
          fontFamily="Satoshi-Regular"
          color="black"
          onClick={() => handleGoToAd(url)}
        >
          See it
        </Button>
      </Box>
    </Flex>
  );
}
