import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectCurrentToken } from '../Redux/features/auth/authSlice';

import RegisterForm from '../Components/RegisterForm';

export default function RegisterPage() {
  const token = useSelector(selectCurrentToken);

  // Si ya tiene token y user, redirigimos a la página de retos (principal)
  if (token) {
    return <Navigate to="/dashboard" />;
  }

  return <RegisterForm />;
}
