import { Box, Flex, Text } from '@chakra-ui/react';

import { AddIcon } from '@chakra-ui/icons';

import DashboardCompetitorItem from './DashboardCompetitorItem';

export default function DashboardCompetitors({
  brandData,
  brandPlan,
  onOpenPricingTableModal,
  onOpenAddNewCompetitorModal,
}) {
  const { competitors = [] } = brandData;
  return (
    <Box mr={4}>
      <Text fontFamily="Switzer-Bold" fontWeight="bold" fontSize="24px">
        Competitors you are Adstracking
      </Text>
      {/* Mostrar si no tiene plan */}
      {brandPlan === 'noplan' && competitors.length === 1 && (
        <Box
          display="inline-block"
          px={2}
          py={1}
          borderRadius="6px"
          border="1px solid #E5E7EB"
          mt={8}
          fontFamily="Satoshi-Regular"
        >
          <Text>
            All your tracks are inactive due to you don’t have any plan.{' '}
            <u style={{ cursor: 'pointer' }} onClick={onOpenPricingTableModal}>
              Suscribe to one!
            </u>
          </Text>
        </Box>
      )}

      <Box mt={8}>
        <Flex flexWrap="wrap" mb="0px" fontFamily="Satoshi-Medium">
          {/* Marcas */}
          {competitors &&
            competitors.length > 0 &&
            competitors.map((competitor) => {
              return <DashboardCompetitorItem key={competitor.id} competitorData={competitor} />;
            })}

          {/* Card añadir nueva marca */}
          <Flex
            height="80px"
            mr={4}
            mb={4}
            p={2}
            cursor="pointer"
            boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.1)"
            alignItems="center"
            borderRadius="12px"
            border="1px solid #E5E7EB"
            w="250px"
            onClick={onOpenAddNewCompetitorModal}
            bg="black"
            color="white"
          >
            <Box mr={4}>
              <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                borderRadius="6px"
                w="60px"
                h="60px"
                bg="#4C4C4C"
              >
                <AddIcon boxSize="20px" />
              </Box>
            </Box>
            <Box>
              <Text lineHeight={1.2} fontWeight="bold" mb={1} fontSize="16px">
                Add new <br /> brand
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}
