import {
  Box,
  Flex,
  Image,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Button,
  MenuItem,
  MenuDivider,
  IconButton,
} from '@chakra-ui/react';

import { useDispatch } from 'react-redux';

import { logOut } from '../../Redux/features/auth/authSlice';

import { ArrowForwardIcon, ChatIcon } from '@chakra-ui/icons';

import AdstrackLogo from '../../Assets/Images/adstrack-isotipo-alone.svg';

export default function Header({ brandPlan, onOpenPricingTableModal, onOpenFeedbackModal }) {
  const dispatch = useDispatch();

  const goToBillingStripePortal = () => {
    window.open('https://billing.stripe.com/p/login/aEUbMGaMA5SJaGYaEE', '_blank').focus();
  }
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      borderBottom="1px solid #E5E7EB"
      height="88px"
      px="40px"
    >
      <Flex alignItems="center">
        <Box
          mr={3}
          boxShadow="-2px 2px 20px 0px rgba(241, 56, 255, 0.3)"
          bg="white"
          borderRadius="10px"
          p={2}
        >
          <Image height="30px" width="30px" src={AdstrackLogo} />
        </Box>

        <Text fontSize="24px" fontWeight="black" fontFamily="Switzer-Extrabold">
          Adstrack
        </Text>
      </Flex>
      <Box>
        <IconButton
          _hover={{ bg: 'whitesmoke' }}
          bg="white"
          border="1px solid #E5E7EB"
          size="md"
          fontFamily="Satoshi-Regular"
          mr={2}
          variant="ghost"
          onClick={onOpenFeedbackModal}
          icon={<ChatIcon />}
        />
        <Button
          _hover={{ bg: 'whitesmoke' }}
          bg="white"
          border="1px solid #E5E7EB"
          size="md"
          fontFamily="Satoshi-Regular"
          mr={2}
          variant="ghost"
          onClick={onOpenPricingTableModal}
        >
          {brandPlan === 'noplan' ? 'Subscribe' : 'Upgrade'}
        </Button>
        <Menu>
          <MenuButton
            _hover={{ bg: 'whitesmoke' }}
            bg="white"
            border="1px solid #E5E7EB"
            size="md"
            as={Button}
            rightIcon={<ArrowForwardIcon />}
            fontFamily="Satoshi-Regular"
          >
            My Account
          </MenuButton>
          <MenuList>
            {/* <MenuItem fontWeight="600" fontFamily="Satoshi-Regular">
              Profile
            </MenuItem> */}
            <MenuItem onClick={() => goToBillingStripePortal()} fontWeight="600" fontFamily="Satoshi-Regular">
              Billing
            </MenuItem>
            <MenuDivider />

            <MenuItem
              onClick={() => dispatch(logOut())}
              fontWeight="600"
              fontFamily="Satoshi-Regular"
            >
              Log out
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
}

// si acabas de entrar y no hay nada: subscribe
// si tienes 1 track y no plan: Active  tracks
// si tienes plan ya contratado y hay superior: Upgrade
