import { Button, Flex } from '@chakra-ui/react';

const Pagination = ({ currentPage, setCurrentPage, hasMore }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" m={4}>
      <Button
        onClick={() => setCurrentPage(currentPage - 1)}
        isDisabled={currentPage <= 1}
      >
        Previous
      </Button>
      <span>Page {currentPage}</span>
      <Button
        onClick={() => setCurrentPage(currentPage + 1)}
        isDisabled={!hasMore}
      >
        Next
      </Button>
    </Flex>
  );
};

export default Pagination;

