import { Box, Text } from '@chakra-ui/react';

import Header from '../Components/Header/Header';

export default function ProfilePage() {
  return (
    <Box maxWidth="1200px" margin="0 auto">
      <Header />
      <Box mt="40px">
        <Text mb={4} fontSize="20px">
          Profile
        </Text>
      </Box>
    </Box>
  );
}
