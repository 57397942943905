// Main imports
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Public pages
import RegisterPage from './Pages/RegisterPage';
import LoginPage from './Pages/LoginPage';

// HOC Routers
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';

// Private Pages
import DashboardPage from './Pages/DashboardPage';

// Error pages
import ErrorPage from './Pages/ErrorPage';
import ProfilePage from './Pages/ProfilePage';

// Routing
const router = createBrowserRouter([
  {
    path: '/',
    element: <RegisterPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute>
        <DashboardPage />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/dashboard/profile',
    element: <ProfilePage />,
    errorElement: <ErrorPage />,
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
