import { Flex, Text } from '@chakra-ui/react';

export default function Footer() {
  return (
    <Flex
      borderTop="1px solid #E5E7EB"
      fontFamily="Satoshi-Medium"
      alignItems="center"
      height="40px"
      px="40px"
      mt="auto"
    >
      <Flex margin="0 auto">
        <Text fontSize="12px" mr={2}>
          Adstrack.app is powered by CRUWI
        </Text>
        {/* <Text fontSize="12px" mr={2}>
          |
        </Text>
        <Text fontSize="12px" mr={2}>
          Terms and conditions
        </Text>
        <Text fontSize="12px" mr={2}>
          |
        </Text>
        <Text fontSize="12px">Legal</Text> */}
      </Flex>
    </Flex>
  );
}
