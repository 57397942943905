import { useDispatch } from 'react-redux';

import { Flex, Text, Box } from '@chakra-ui/react';

import { logOut } from '../Redux/features/auth/authSlice';

export default function ErrorLoadingDataPage() {
  const dispatch = useDispatch();
  return (
    <Flex justifyContent="center" alignItems="center" bg="white" width="100%" minH="100dvh">
      <Box p={4}>
        <Text textAlign="center">
          There is an error with your data. <br /> Please{' '}
          <span onClick={() => dispatch(logOut())} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
            log out
          </span>{' '}
          and try again.
        </Text>
      </Box>
    </Flex>
  );
}
