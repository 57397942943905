import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  useToast,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

import CustomLink from './CustomLink/CustomLink';

import { GoogleLogin } from '@react-oauth/google';

import { setCredentials } from '../Redux/features/auth/authSlice';
import { useLoginMutation, useLoginWithGoogleMutation } from '../Redux/features/auth/authApiSlice';

export default function LoginForm() {
  const toast = useToast();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [loginBrand, { isLoading }] = useLoginMutation();
  const [loginWithGoogle] = useLoginWithGoogleMutation();

  const [email, setEmail] = useState('');
  const handleChangeEmail = (e) => setEmail(e.target.value);

  const [password, setPassword] = useState('');
  const handleChangePassword = (e) => setPassword(e.target.value);

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const handleSubmit = async () => {
    const brandData = {
      email,
      password,
    };

    try {
      const userLoginData = await loginBrand(brandData).unwrap();
      console.log(userLoginData);
      setEmail('');
      setPassword('');
      dispatch(setCredentials(userLoginData));
      navigate('/dashboard');
    } catch (error) {
      toast({
        status: 'error',
        description: 'There is an error with your login',
      });
      console.log(error);
    }
  };

  return (
    <Box minH="100dvh" fontFamily="Satoshi-Regular">
      <Container
        maxW="lg"
        py={{
          base: '12',
          md: '24',
        }}
        px={{
          base: '0',
          sm: '8',
        }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            <Box margin="0 auto" textAlign="center">
              <Text fontSize="24px" fontWeight="black" fontFamily="Switzer-Extrabold">
                Adstrack
              </Text>
            </Box>

            <Stack
              spacing={{
                base: '2',
                md: '3',
              }}
              textAlign="center"
            >
              <Text fontFamily="Switzer-Semibold" fontWeight="bold" fontSize="24px">
                Log in
              </Text>
            </Stack>
          </Stack>
          <Box
            py={{
              base: '0',
              sm: '8',
            }}
            px={{
              base: '4',
              sm: '10',
            }}
            bg={{
              base: 'transparent',
              sm: 'bg.surface',
            }}
            borderRadius={{
              base: 'none',
              sm: 'xl',
            }}
          >
            <Stack spacing="6">
              <Stack spacing="5">
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    onChange={handleChangeEmail}
                    value={email}
                    id="email"
                    type="email"
                    placeholder="Company email"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <InputGroup size="md">
                    <Input
                      value={password}
                      onChange={handleChangePassword}
                      id="password"
                      pr="4.5rem"
                      type={show ? 'text' : 'password'}
                      placeholder="Enter password"
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleClick}>
                        {show ? 'Hide' : 'Show'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Stack>

              <Stack spacing="6">
                <Button isLoading={isLoading} onClick={handleSubmit} size="md" colorScheme="blue">
                  Sign in
                </Button>
                <HStack>
                  <Divider />
                  <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                    or continue with
                  </Text>
                  <Divider />
                </HStack>
                <Box>
                  <GoogleLogin
                    width="368px"
                    size="large"
                    auto_select
                    useOneTap
                    ux_mode="popup"
                    context="signin"
                    text="signin_with"
                    onSuccess={async (credentialResponse) => {
                      const googleResultData = {
                        token: credentialResponse.credential,
                      };

                      try {
                        const userLoginData = await loginWithGoogle(googleResultData).unwrap();
                        dispatch(setCredentials(userLoginData));
                        navigate('/dashboard');
                      } catch (error) {
                        toast({
                          status: 'error',
                          description: 'There is an error with your registration with Google',
                        });
                        console.log(error);
                      }
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />
                </Box>
              </Stack>
            </Stack>
          </Box>
          <Box textAlign="center">
            <Text color="gray">
              Don't have an account?{' '}
              <CustomLink className="link-hover-underline" to="/">
                Create one
              </CustomLink>
            </Text>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
