import apiSlice from '../../api/apiSlice';

export const checkoutApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    buyPlan: builder.mutation({
      query: (planData) => ({
        url: 'checkout',
        method: 'POST',
        body: planData,
      }),
      invalidatesTags: ['BrandData'],
    }),
  }),
});

export const { useBuyPlanMutation } = checkoutApiSlice;
