import apiSlice from '../../api/apiSlice';

export const adsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAds: builder.query({
      query: ({ pageNumber, pageSize }) => ({
        url: `ads`,
        params: { pageNumber, pageSize },
      }),
      providesTags: (result, error, { pageNumber = 1 }) => [{ type: 'Ads', pageNumber }],
    }),
  }),
});

export const { useGetAdsQuery } = adsApiSlice;
