import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export let CRUWI_API_URL = 'https://app.adstrack.app/api/';

if (process.env.NODE_ENV !== 'production') {
  CRUWI_API_URL = 'http://localhost:3001/api/';
}

const baseQuery = fetchBaseQuery({
  baseUrl: CRUWI_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const { token } = getState().auth;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export default createApi({
  baseQuery,
  tagTypes: ['BrandData'],
  endpoints: () => ({}),
});
