import { Box, Flex, Text, Button } from '@chakra-ui/react';

import { EmailIcon, BellIcon, PhoneIcon } from '@chakra-ui/icons';

import { FaSlack } from 'react-icons/fa';

export default function DashboardConfigurations({ onOpenNotificationsEmailModal }) {
  return (
    <Box
      borderRadius="6px"
      maxW="350px"
      boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.1)"
      border="1px solid #E5E7EB"
    >
      <Box p={6}>
        <Text fontFamily="Switzer-Bold" fontWeight="bold" fontSize="20px" mb={2}>
          Some configurations
        </Text>
        <Text fontFamily="Satoshi-Regular" fontSize="16px">
          Check where you are going to receive notifications
        </Text>
        <Flex flexWrap="wrap" mt={4}>
          <Button
            mr={4}
            mb={4}
            fontFamily="Satoshi-Regular"
            leftIcon={<EmailIcon />}
            rightIcon={
              <Flex
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                border="2px solid #00CE00"
                w="16px"
                h="16px"
                bg="white"
              >
                <Box borderRadius="50%" w="6px" h="6px" bg="#00CE00" />
              </Flex>
            }
            variant="outline"
            onClick={onOpenNotificationsEmailModal}
          >
            Email
          </Button>
          <Button
            mr={4}
            mb={4}
            fontFamily="Satoshi-Regular"
            rightIcon={
              <Flex
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                border="2px solid rgba(0, 0, 0, 0.4)"
                w="16px"
                h="16px"
                bg="white"
              >
                <Box borderRadius="50%" w="6px" h="6px" bg="rgba(0, 0, 0, 0.4)" />
              </Flex>
            }
            leftIcon={<FaSlack />}
            variant="outline"
            isDisabled
          >
            Slack
          </Button>
          <Button
            isDisabled
            mr={4}
            fontFamily="Satoshi-Regular"
            rightIcon={
              <Flex
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                border="2px solid rgba(0, 0, 0, 0.4)"
                w="16px"
                h="16px"
                bg="white"
              >
                <Box borderRadius="50%" w="6px" h="6px" bg="rgba(0, 0, 0, 0.4)" />
              </Flex>
            }
            leftIcon={<BellIcon />}
            variant="outline"
          >
            Push
          </Button>
          <Button
            isDisabled
            mr={4}
            fontFamily="Satoshi-Regular"
            rightIcon={
              <Flex
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                border="2px solid rgba(0, 0, 0, 0.4)"
                w="16px"
                h="16px"
                bg="white"
              >
                <Box borderRadius="50%" w="6px" h="6px" bg="rgba(0, 0, 0, 0.4)" />
              </Flex>
            }
            leftIcon={<PhoneIcon />}
            variant="outline"
          >
            Other
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}
