import apiSlice from '../../api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (registerBrandData) => ({
        url: 'auth/signup',
        method: 'POST',
        body: registerBrandData,
      }),
      providesTags: ['BrandData'],
    }),
    login: builder.mutation({
      query: (loginBrandData) => ({
        url: 'auth/login',
        method: 'POST',
        body: loginBrandData,
      }),
      providesTags: ['BrandData'],
    }),
    update: builder.mutation({
      query: (updateBrandData) => ({
        url: 'me',
        method: 'PUT',
        body: updateBrandData,
      }),
      invalidatesTags: ['BrandData'],
    }),
    getBrandData: builder.query({
      query: () => 'me',
      providesTags: ['BrandData'],
    }),
    registerWithGoogle: builder.mutation({
      query: (googleRegisterData) => ({
        url: 'auth/google',
        method: 'POST',
        body: googleRegisterData,
      }),
      providesTags: ['BrandData'],
    }),
    loginWithGoogle: builder.mutation({
      query: (googleLoginData) => ({
        url: 'auth/google',
        method: 'POST',
        body: googleLoginData,
      }),
      providesTags: ['BrandData'],
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useUpdateMutation,
  useGetBrandDataQuery,
  useRegisterWithGoogleMutation,
  useLoginWithGoogleMutation,
} = authApiSlice;
