import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectCurrentToken } from '../../Redux/features/auth/authSlice';

export default function ProtectedRoute({ children, redirectTo = '' }) {
  const token = useSelector(selectCurrentToken);
  if (!token) {
    return <Navigate to={`/${redirectTo}`} replace />;
  }
  return children;
}
