import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Heading,
  Flex,
  Image,
} from '@chakra-ui/react';

import { ArrowForwardIcon } from '@chakra-ui/icons';

import { useBuyPlanMutation } from '../../Redux/features/checkout/checkoutApiSlice';

import AdstrackLogo from '../../Assets/Images/adstrack-isotipo-alone.svg';

export default function ModalPricingTable({ brandPlan, onClose, isOpen }) {
  const [buyPlan] = useBuyPlanMutation();

  const handleSelectPlan = async (plan) => {
    try {
      const data = {
        productId: plan,
      };

      const buyPlanResult = await buyPlan(data).unwrap();
      window.location.replace(buyPlanResult.data);
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay backdropFilter="blur(1px) hue-rotate(90deg)" />
      <ModalContent
        overflowY="auto"
        width="90vw"
        maxW="1000px"
        height="auto"
        minH="800px"
        maxH="90vw"
        my="20px"
      >
        <ModalCloseButton />
        <ModalBody p={5} display="flex" alignItems="center">
          <Flex flexDir="column" maxWidth="1200px" m="0 auto">
            {/* Logo  Adstrack */}
            <Flex margin="0 auto" alignItems="center">
              <Box
                mr={3}
                boxShadow="-2px 2px 20px 0px rgba(241, 56, 255, 0.3)"
                bg="white"
                borderRadius="10px"
                p={2}
              >
                <Image height="30px" width="30px" src={AdstrackLogo} />
              </Box>
            </Flex>
            {/* Título y subtítulo */}
            <Box p={6}>
              <Text textAlign="center" pt={4} fontFamily="Switzer-Bold" fontSize="24px">
                Select your plan
              </Text>
              <Text textAlign="center" mb={3} fontSize="16px" fontFamily="Satoshi-Medium">
                Choose the plan you need
              </Text>
            </Box>
            {/* Precios tablas */}
            <Flex flexDir={['column', 'column', 'row', 'row', 'row']}>
              {/* Pricing Card for Freelance Plan */}
              <Box mb={4} mr={4} p={5} borderWidth="1px" borderRadius="20px">
                <Heading fontFamily="Switzer-Bold" fontSize="24px">
                  Freelance
                </Heading>
                <Text fontFamily="Switzer-Regular" mt={4}>
                  <span style={{ fontFamily: 'Switzer-Bold', fontSize: '45px' }}>€9</span>/month
                </Text>
                <Button
                  mt={10}
                  bg="black"
                  color="white"
                  _hover={{ bg: 'black', color: 'white' }}
                  size="sm"
                  fontFamily="Satoshi-Regular"
                  rightIcon={<ArrowForwardIcon />}
                  onClick={() => handleSelectPlan('prod_Q20EaxIjcwg6B6')}
                  isDisabled={brandPlan === 'prod_Q20EaxIjcwg6B6' || brandPlan === 'prod_Q1zn5G7cc5clff'}
                >
                  Select plan
                </Button>
                <Box fontSize="16px" fontFamily="Satoshi-Medium" mt={4}>
                  <Text>✅ Track 1 competitor</Text>
                  <Text>✅ Track on Meta & TikTok</Text>
                  <Text>✅ Email notifications</Text>
                </Box>
              </Box>

              {/* Pricing Card for Brand Plan */}
              <Box
                boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
                position="relative"
                mb={4}
                mr={4}
                p={5}
                borderWidth="1px"
                borderRadius="20px"
              >
                <Heading fontFamily="Switzer-Bold" fontSize="24px">
                  Brand
                </Heading>
                <Text fontFamily="Switzer-Regular" mt={4}>
                  <span style={{ fontFamily: 'Switzer-Bold', fontSize: '45px' }}>€19</span>/month
                </Text>
                <Button
                  mt={10}
                  bg="black"
                  color="white"
                  _hover={{ bg: 'black', color: 'white' }}
                  size="sm"
                  fontFamily="Satoshi-Regular"
                  rightIcon={<ArrowForwardIcon />}
                  onClick={() => handleSelectPlan('prod_Q1zn5G7cc5clff')}
                  isDisabled={brandPlan === 'prod_Q1zn5G7cc5clff'}
                >
                  Select plan
                </Button>
                <Box fontSize="16px" fontFamily="Satoshi-Medium" mt={4}>
                  <Text>✅ Track up to 5 competitors</Text>
                  <Text>✅ Track on Meta & TikTok</Text>
                  <Text>✅ Email notifications</Text>
                  <Text>✅ Slack integration</Text>
                </Box>
                <Box
                  border="1px solid #2222221a"
                  top={'10px'}
                  right={'10px'}
                  borderRadius="10px"
                  position="absolute"
                  fontFamily="Satoshi-Regular"
                  py="2px"
                  color="white"
                  px="10px"
                  bg="#000"
                >
                  <Text fontWeight="bold" fontSize="12px" fontFamily="Satoshi-Regular" opacity={1}>
                    Most popular
                  </Text>
                </Box>
              </Box>

              {/* Pricing Card for Agency Plan */}
              <Box mb={4} p={5} borderWidth="1px" borderRadius="20px">
                <Heading fontFamily="Switzer-Bold" fontSize="24px">
                  Agency
                </Heading>
                <Text fontFamily="Switzer-Regular" mt={4}>
                  <span style={{ fontFamily: 'Switzer-Bold', fontSize: '45px' }}>€49</span>/month
                </Text>
                <Button
                  mt={10}
                  bg="black"
                  color="white"
                  _hover={{ bg: 'black', color: 'white' }}
                  size="sm"
                  fontFamily="Satoshi-Regular"
                  rightIcon={<ArrowForwardIcon />}
                  onClick={() => handleSelectPlan('prod_Q1zsv6eYqVlDDZ')}
                >
                  Select plan
                </Button>
                <Box fontSize="16px" fontFamily="Satoshi-Medium" mt={4}>
                  <Text>✅ Track up to 20 competitors</Text>
                  <Text>✅ Track on Meta & TikTok</Text>
                  <Text>✅ Email notifications</Text>
                  <Text>✅ Slack integration</Text>
                  <Text>✅ Categorize by client</Text>
                </Box>
              </Box>
            </Flex>
            {/* Custom plan text */}
            <Box opacity={0.75} textAlign="center" mt={4}>
              <Text fontFamily="Satoshi-Medium" fontSize="16px">
                Need a custom plan? Contact us <u>hello@adstrack.app</u>
              </Text>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button fontFamily="Satoshi-Regular" size="sm" variant="outline" onClick={onClose}>
            Close pricing
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
