import {
  Box,
  Flex,
  Text,
  Image,
  Popover,
  PopoverTrigger,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
} from '@chakra-ui/react';

import { InfoOutlineIcon } from '@chakra-ui/icons';

import MetaIcon from '../../Assets/Images/meta.png';
import TikTokIcon from '../../Assets/Images/tiktok.png';

import capitalizeFirstLetter from '../../Helpers/capitalizeFirstLetter';

export default function DashboardCompetitorItem({ competitorData }) {
  const { logoUrl, name, status = 'pending', sources = [] } = competitorData;

  let popOverText =
    'This competitor is being processed by our system. This process can take up to 24 hours.';
  if (status === 'inactive') {
    popOverText =
      'This competitor is inactive. Ads are not being tracked. This is because you are not subscribed to any plan.';
  }
  if (status === 'active') {
    popOverText = 'This competitor is active. All their ads are being monitored by our system.';
  }

  return (
    <Flex
      height="80px"
      mr={4}
      mb={4}
      p={2}
      boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.1)"
      border="1px solid #E5E7EB"
      alignItems="center"
      borderRadius="12px"
      w="250px"
    >
      <Box mr={4}>
        <Image borderRadius="6px" w="63px" h="63px" src={logoUrl} />
      </Box>
      <Box>
        <Text fontFamily="Satoshi-Medium" mb={1} fontSize="16px">
          {capitalizeFirstLetter(name)}
        </Text>
        <Flex mb={1}>
          {sources.includes('tiktok') && (
            <Box borderRadius="4px" bg=" #E5E7EB" mr={1}>
              <Image width="16px" src={TikTokIcon} />
            </Box>
          )}

          {sources.includes('meta') && (
            <Box borderRadius="4px" bg=" #E5E7EB">
              <Image width="16px" src={MetaIcon} />
            </Box>
          )}
        </Flex>
        <Flex alignContent="center">
          <Text
            mr="2px"
            textDecor="underline"
            color="rgba(0, 0, 0, 0.48)"
            fontSize="12px"
            fontFamily="Satoshi-Regular"
          >
            {capitalizeFirstLetter(status)}
          </Text>
          <Popover placement="right">
            <PopoverTrigger>
              <InfoOutlineIcon mt="2px" color="rgba(0, 0, 0, 0.48)" width="10px" />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader fontFamily="Satoshi-Regular" fontSize="12px">
                Competitor status
              </PopoverHeader>
              <PopoverBody>
                <Text fontFamily="Satoshi-Regular" fontSize="12px">
                  {popOverText}
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      </Box>
    </Flex>
  );
}
