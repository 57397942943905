import apiSlice from '../../api/apiSlice';

export const competitorsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addCompetitor: builder.mutation({
      query: (competitorData) => ({
        url: 'competitors',
        method: 'POST',
        body: competitorData,
      }),
      invalidatesTags: ['BrandData'],
    }),
  }),
});

export const { useAddCompetitorMutation } = competitorsApiSlice;
