import './ScreenLoader.css';

import AdstrackLogo from '../../Assets/Images/adstrack-isotipo-alone.svg';

export default function ScreenLoader() {
  return (
    <div className="full-page-loader">
      <img
        width="100"
        src={AdstrackLogo}
        alt="Adstrack Loading.."
      />
    </div>
  );
}
