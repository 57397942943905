import { createSlice } from '@reduxjs/toolkit';

// Get token from localstorage
const token = JSON.parse(localStorage.getItem('token'));

const initialState = {
  token: token || null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { token: newToken } = action.payload;
      state.token = newToken;
      localStorage.setItem('token', JSON.stringify(newToken));
    },
    logOut: (state) => {
      state.token = null;
      localStorage.removeItem('token');
      window.location.reload();
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentToken = (state) => state.auth.token;
